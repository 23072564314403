import React from 'react';
import StoryblokImage from '../images/StoryblokImage';
import { Link } from 'gatsby';

interface ComponentProps {
  name: string;
  image: string;
  instagramLink: string;
  author_slug:string;
}

const Author: React.FunctionComponent<ComponentProps> = ({
  name,
  image,
  instagramLink,
  author_slug
}) => {

    const userInstagramHandle = instagramLink !== null ? instagramLink.slice(instagramLink.indexOf('com/') + 4, instagramLink.lastIndexOf('/')) : "";
    return (
        <div>
            <Link to={`chef/${author_slug}`}>
                <div className="inline-block text-center pt-1">
                    <StoryblokImage src={image} alt={"Author"} dimensions={"100x100"} smartCropping={true} _className={"rounded w-8"}/>
                </div>
            </Link>
            <div className="inline-block text-center align-top">
                <div className="text-sm text-left ml-2 mt-1 pb-2 h-12">
                    <p className="text-gray-900 leading-none text-sm mt-0 mb-0"><Link to={`/chef/${author_slug.toLocaleLowerCase()}/`}>{name}</Link></p>
                    <a href={instagramLink} target="_blank" rel="noopener noreferrer" className="text-gray-600 leading-none text-xs mt-1 mb-0 self-start">@{userInstagramHandle}</a>
                </div>
            </div>
        </div>
    )
};

export default Author;