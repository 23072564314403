import React, { FormEvent, useState } from 'react';
import Colors from '../../constants/Colors';
import { FaSearch } from 'react-icons/fa';
import { Index } from "elasticlunr"
import { useStaticQuery, graphql } from 'gatsby';

interface ComponentProps {
    value?: string,
    handleChange?: (event: FormEvent<HTMLInputElement>) => void,
    handleSubmit?: (event: any) => void,
    placeholder?: string,
};

const Searchbar: React.FunctionComponent<ComponentProps> = ({ value, handleChange, handleSubmit, placeholder}) => {

    return(
        <form onSubmit={handleSubmit}>
            <FaSearch 
                style={searchIcon}
                size={25}
            />
            <input
            type="text"
            value={value}
            placeholder={placeholder}
            style={textInput}
            onChange={handleChange}
            />
            <button className="btn" onClick={handleSubmit} />
        </form>
    );
}

Searchbar.defaultProps = {
    placeholder: 'Søg efter opskrifter her...'
} as Partial<ComponentProps>;

const textInput = {
    boxSizing: 'border-box',
	fontSize: 18,
	background: Colors.lightThemeColor,
	color: Colors.green,
    paddingTop: 13,
    paddingBottom: 15,
    paddingRight: 13,
    paddingLeft: 50,
    borderWidth: 3,
    borderColor: Colors.green,
    outline: 'none',
	width: '100%',
	fontWeight: 'normal',
    borderRadius: 5,
} as React.CSSProperties;

const searchIcon = {
    color: Colors.green,
    position: 'relative',
    top: 43,
    left: 14
} as React.CSSProperties;

export default Searchbar;