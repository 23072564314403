import React, { useState } from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import Searchbar from '../components/searchbar/Searchbar';
import Routes from '../constants/Routes';
import { RecipeModel } from '../models/RecipeModel';
import SmallContainer from '../components/layout/SmallContainer';
import WideContainer from '../components/layout/WideContainer';
import RecipeCard from '../components/cards/RecipeCard';
import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';
import { Index } from "elasticlunr"
import {Helpers} from '../services/Helpers'
import _ from 'lodash';    

interface Props extends PageProps {
  data: {
    allStoryblokEntry: {
      edges: any;
    };
    siteSearchIndex: {
      index: any;
    };
  };
}

const OpskrifterPage: React.FunctionComponent<Props> = ({ data }) => {

  const recipeData: Array<any> = data.allStoryblokEntry.edges;
  const recipes: RecipeModel[] = data.allStoryblokEntry.edges.map(edge => JSON.parse(edge.node.content));


  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const searchIndex = data.siteSearchIndex.index;

  let index;

  const getOrCreateIndex = () =>
  index
    ? index
    : // Create an elastic lunr index and hydrate with graphql query results
      Index.load(searchIndex)

  const search = evt => {
      const query = evt.target.value
      index = getOrCreateIndex()
      setResults(index
          .search(query.toLowerCase(), { expand: true })
          // Map over each ID and return the full document
          .map(({ ref }) => index.documentStore.getDoc(ref)))
      
      setQuery(query);
      
  }

  return (
    <Layout>
      <MetaData title="Opskrifter" />
      <OrganizationStructuredData />
      <div className="bg-white">
        <SmallContainer className="pt-4 text-center">
          <h1>Opskrifter</h1>
          <hr className="w-48 text-center mx-auto mb-6 border-2" />
          <Searchbar handleChange={search} />
        </SmallContainer>
        <WideContainer>
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8">
              {results.map((item: any, index: number) => {
                  let recipe : RecipeModel = item.content
                  if(!Helpers.objectIsMissingValue(recipe,["headline","image","author"])){
                    return(<div key={index}>
                      <RecipeCard 
                          title={recipe.headline}
                          image={recipe.image.filename}
                          imageAlt={recipe.image.alt || "opskrift navn"}
                          link={`/${Routes.RECIPEPREFIX}/${item.path}/`}
                          authorName={recipe.author.content.author_name}
                          authorImage={recipe.author.content.author_image[0].filename}
                          authorInstagramLink={recipe.author.content.author_instagram_url}
                          author_slug={recipe.author.slug}
                      />
                  </div>) 
                  }                  
                }
              )}
          </div>
          {query === "" && 
            <div className="grid grid-cols-1 md:grid-cols-3 mt-8">
              {recipeData.map((item: any, index: number) =>
                  {
                    let recipe : RecipeModel = JSON.parse(item.node.content)
                    // Makes sure that object isn't missing values and only displays 9 of them. 
                    if(!Helpers.objectIsMissingValue(recipe,["headline","image","author"]) && index < 9){
                      return(<div key={index}>
                        <RecipeCard 
                            title={recipe.headline}
                            image={recipe.image.filename}
                            imageAlt={recipe.image.alt || recipe.image.filename}
                            link={`/${Routes.RECIPEPREFIX}/${item.node.slug}/`}
                            authorName={recipe.author.content.author_name}
                            authorImage={recipe.author.content.author_image[0].filename}
                            authorInstagramLink={recipe.author.content.author_instagram_url}
                            author_slug={recipe.author.slug}
                        />
                    </div>) 
                    }           
                  }
              )}
          </div>
        }
        {(results.length === 0) && (query !== "") && 
            <div className="grid grid-cols-1 md:grid-cols-3 mt-8">
              <h3>Vi kunne ikke finde nogen opskrifter som matcher din søgning</h3>
          </div>
      }
        </WideContainer>
      </div>
    </Layout>
  );
};

export const query = graphql`
query RecipesQuery {
  allStoryblokEntry (filter: {field_component: {eq: "Post"}}){
            edges {
              node {
                name
                slug
                content
                field_component
              }
            }
          }
          siteSearchIndex {
              index
          }
}`

export default OpskrifterPage;