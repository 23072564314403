import { RecipeModel } from "../models/RecipeModel";

const sanitizeUrl =  (url : string) : string => {
    let sanitizedUrl: string = url
        .toLowerCase()
        .replace('æ', 'ae')
        .replace('ø', 'oe')
        .replace('å', 'aa')
        .replace(' ', '-');

    return sanitizedUrl;
}

const getInstagramUser =  (instagramLink : string) : string => {
    return '@' + instagramLink.slice(instagramLink.indexOf('com/') + 4, instagramLink.lastIndexOf('/'));
}

const objectIsMissingValue = (obj:RecipeModel, keys:Array<string>) : Boolean => {
    for(var i=0; i<keys.length; i++){
        if(obj.hasOwnProperty(keys[i]) !== true){
            return true;
        }
    }
    return false;
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>
  (obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

export const Helpers = {
    sanitizeUrl,
    getInstagramUser,
    objectIsMissingValue,
    hasOwnProperty
}
