import React from 'react';
import Author from '../recipe/Author';
import { Link } from 'gatsby';
import StoryblokImage from '../images/StoryblokImage';

interface ComponentProps {
  title: string;
  image: string;
  imageAlt: string;
  authorImage: string;
  authorName: string;
  authorInstagramLink: string;
  link: string;
  author_slug: string;
}

const RecipeCard: React.FunctionComponent<ComponentProps> = ({
  title,
  image,
  imageAlt,
  authorImage,
  authorName,
  authorInstagramLink,
  link,
  author_slug
}) => {
    return (
          <div className="m-2">
            <Link to={link}>
              <StoryblokImage src={image} alt={imageAlt} dimensions={"400x400"} smartCropping={true} _className={"w-full rounded-lg"}/>
              <div className="mt-2">
                  <h4 className="font-bold text-xl mb-1 mt-4">{title}</h4>
              </div>
            </Link>
            <Author
              image={authorImage}
              name={authorName}
              instagramLink={authorInstagramLink}
              author_slug={author_slug}
            />
          </div>
    )
};

RecipeCard.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default RecipeCard;